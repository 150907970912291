import { React, createElement } from "react"
import { Image } from "react-bootstrap"
import { AiOutlineCalendar, AiOutlineEuro, AiOutlineMessage, AiOutlineScissor, AiOutlineUser } from "react-icons/ai"
import { BsUpcScan } from "react-icons/bs"
import { ImStatsBars } from "react-icons/im"
import { useDashboard } from "../../contexts/DashboardProvider"

import { FiPower } from "react-icons/fi"
import { useNavigate } from "react-router-dom"
import { useAuthentication } from "../../contexts/AuthenticationProvider"
import { useDatabase } from "../../contexts/DatabaseProvider"
import { MaterialUISwitch, ROLES, darkTheme, lightTheme } from "../../helper"
import logo from "../../logo-ldn-square.png"
import PermissionGate from "../permissions/PermissionGate"
import useComponentVisible from "./main-panel/easy-calendar/components/useComponentVisible"
import { useSalon } from "../../contexts/SalonProvider"
import IconHolder from "./main-panel/easy-calendar/elements/IconHolder"
import { useMessages } from "../../contexts/MessagesProvider"
import ConnectionStatus from "../ConnectionStatus"

function ExpandingSection({ children, isComponentVisible, refSection, extraComponent }) {
    return (
        <div className="relative w-full z-10">
            {children}
            <div ref={refSection}>
                {isComponentVisible && (
                    <div className="absolute left-full top-1/2 transform -translate-y-1/3 translate-x-[2px]  flex flex-col min-w-max !p-3 rounded-md bg-white shadow-md">{extraComponent}</div>
                )}
            </div>
        </div>
    )
}

function SalonSelector({ selectedSalon, setSelectedSalon, salons }) {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)

    function SalonsList() {
        return (
            <div className="flex flex-col gap-3">
                {salons.map((salon) => (
                    <button
                        key={salon.id}
                        className="flex"
                        onClick={() => {
                            setSelectedSalon(salon)
                            setIsComponentVisible(false)
                        }}
                    >
                        <div className="flex flex-row items-center gap-2">
                            <IconHolder item={salon} name={salon.name} textSize="18px" className="!h-[32px] !w-[32px]" />
                            <div className="text-sm text-black/80 "> {salon.name} </div>
                        </div>
                    </button>
                ))}
            </div>
        )
    }

    return (
        <ExpandingSection refSection={ref} isComponentVisible={isComponentVisible} setIsComponentVisible={setIsComponentVisible} extraComponent={<SalonsList />}>
            <button
                onClick={() => {
                    setIsComponentVisible(true)
                }}
            >
                <IconHolder item={selectedSalon} name={selectedSalon.name} className="mt-1 " />
            </button>
        </ExpandingSection>
    )
}

export default function DashboardMode() {
    const { dashboard, setDashboard, receipt, setReceipt, setColorTheme, colorTheme } = useDashboard()
    const { updateAppointments, updateStats, operators, setDate } = useDatabase()
    const { salons, selectedSalon, setSelectedSalon } = useSalon()
    const { logOut } = useAuthentication()
    const { hasNewMessageIncomingConversations } = useMessages()
    const navigate = useNavigate()

    const notificationSpecialDaysRequest = () => {
        return operators.some((operator) => operator.has_specialday_request)
    }

    // console.log(operators)
    const ShowMode = ({ mode, icon, customFunction = () => {}, notification = false }) => {
        return (
            <div className="mb-3" style={{ position: "relative" }}>
                <button
                    className={`border-0 p-2 rounded ${dashboard === mode ? "bg-white/30" : "bg-inherit"}`}
                    onClick={() => {
                        customFunction()
                        setDashboard(mode)
                    }}
                >
                    {createElement(icon, { size: 25, color: "whitesmoke" })}
                </button>

                {notification && <div className="flex flex-none !h-[9px] !w-[9px] bg-[limegreen]" style={{ borderRadius: "999px", position: "absolute", top: "0px", right: "0px" }} />}
            </div>
        )
    }

    return (
        <div className="flex flex-none card" style={{ backgroundColor: colorTheme.primaryColor, borderRadius: "30px", boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.15)", width: "60px" }}>
            {/* <div className="d-flex flex-column align-items-center  mb-3 mt-6">
                <img src={logo} alt="Logo LDN Hair" className="rounded" width={40} />
            </div> */}

            <div className="flex flex-col" style={{ height: "100%" }}>
                <div className="d-flex flex-column align-items-center">
                    <PermissionGate permissions={[ROLES.owner]}>
                        <div className="flex  mt-1 mb-3">
                            <SalonSelector selectedSalon={selectedSalon} setSelectedSalon={setSelectedSalon} salons={salons} />
                        </div>
                    </PermissionGate>

                    

                    <PermissionGate permissions={[ROLES.hairdresser, ROLES.manager, ROLES.salon]}>
                        <Image src={logo} className="flex h-12 w-12 overflow-clip rounded-full mt-2 mb-3" />
                    </PermissionGate>

                    <PermissionGate permissions={[ROLES.hairdresser, ROLES.manager, ROLES.owner]}>
                        <ShowMode
                            mode={"insights"}
                            icon={ImStatsBars}
                            customFunction={() => {
                                setDate(new Date())
                                updateStats(new Date())
                            }}
                        />
                    </PermissionGate>

                    <ShowMode mode={"calendar"} icon={AiOutlineCalendar} />

                    <PermissionGate permissions={[ROLES.hairdresser, ROLES.manager, ROLES.owner]}>
                        <ShowMode mode={"operators"} icon={AiOutlineScissor} notification={notificationSpecialDaysRequest()} />
                    </PermissionGate>

                    <ShowMode mode={"messages"} icon={AiOutlineMessage} notification={hasNewMessageIncomingConversations()} />

                    <PermissionGate permissions={[ROLES.salon, ROLES.manager, ROLES.owner]}>
                        <ShowMode mode={"inventory"} icon={BsUpcScan} />
                        <ShowMode
                            mode={"cash-register"}
                            icon={AiOutlineEuro}
                            customFunction={() => {
                                updateAppointments()
                            }}
                        />
                    </PermissionGate>
                    <ShowMode mode={"customers"} icon={AiOutlineUser} />
                    
                </div>

                <div className="flex flex-col items-center mt-auto mb-2 gap-4">
                    <PermissionGate permissions={[ROLES.salon, ROLES.manager, ROLES.owner]}>
                        <div className="">
                            <MaterialUISwitch
                                checked={receipt}
                                onChange={() => {
                                    if (receipt) {
                                        setColorTheme(darkTheme)
                                    } else {
                                        setColorTheme(lightTheme)
                                    }
                                    setReceipt(!receipt)
                                }}
                            />
                        </div>
                    </PermissionGate>

                    <button
                        className={`relative border-0 p-2 bg-blue-700 rounded-full`}
                        onClick={() => {
                            logOut()
                            navigate("/login")
                        }}
                    >
                        <FiPower size={29} color="whitesmoke" />
                    </button>
                </div>
            </div>
        </div>
    )
}

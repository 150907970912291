import { Client } from "@twilio/conversations"
import React, { useCallback, useContext, useEffect, useRef, useState } from "react"
import LoadingScreen from "../components/dashboard/LoadingScreen"
import { getData, sendData, urls } from "../helper"
import { DateTime } from "luxon"

const MessagesContext = React.createContext()

export function useMessages() {
    return useContext(MessagesContext)
}

export function MessagesProvider({ children }) {
    let client = useRef()
    let skipUpdateConversation = useRef(false)

    const [conversations, setConversations] = useState([])
    const [selectedConversation, setSelectedConversation] = useState("not-selected")
    const [messages, setMessages] = useState([])
    const [loading, setLoading] = useState(true)
    const [lastUpdate, setLastUpdate] = useState(DateTime.local())

    const initializeClient = async () => {
        const response = await getData(urls.twilioToken)
        client.current = new Client(response.twilio_token)
        client.current.on("stateChanged", (state) => {
            if (state === "failed") {
                console.log("Twilio client initialization failed")
                return
            }
            if (state === "initialized") {
                // console.log('Initialized')
                // setClient(clientT)
                return
            }
        })
    }

    const getConversations = async () => {
        let conversationsPaginator = await client.current.getSubscribedConversations()
        return conversationsPaginator.items
    }

    const getMessages = async (conversation) => {
        const messagesPaginator = await conversation.getMessages()
        return messagesPaginator.items
    }

    const addMessage = useCallback((newMessage) => {
        setMessages((messagesT) => {
            return [...messagesT, newMessage]
        })
    }, [])

    const addConversation = useCallback((newConversation) => {
        setConversations((conversationsT) => {
            return [...conversationsT, newConversation]
        })
    }, [])

    const removeConversation = useCallback((conversation) => {
        //Removes conversation from the UI
        setConversations((conversationsT) =>
            conversationsT.filter((conversationT) => {
                return conversationT !== conversation
            })
        )
    }, [])

    const updateConversations = useCallback(async () => {
        if (skipUpdateConversation.current) return

        const conversationsT = await getConversations()
        setConversations(conversationsT)
    })

    const safeSetSelectedConversation = async (conversation) => {
        if (selectedConversation !== "not-selected") selectedConversation.off("messageAdded", addMessage)
        if (conversation !== "not-selected") conversation.setAllMessagesRead()
        setSelectedConversation(conversation)
    }

    const updateTokenClient = useCallback(async () => {
        setLastUpdate(DateTime.local())
        const response = await getData(urls.twilioToken)
        await client.current.updateToken(response.twilio_token)
    }, [])

    //Try first this if the autorefresh does not work and then try by uncommenting the function content if still there are issues
    async function forceRefreshClient() {
        // console.log(client.current)
        cleanUpListeners()
        setSelectedConversation("not-selected")
        setMessages([])
        setConversations([])
        await syncConversations()
        await updateTokenClient()
    }

    function setUpListeners() {
        client.current.on("conversationJoined", addConversation)
        client.current.on("conversationLeft", removeConversation)
        client.current.on("conversationUpdated", updateConversations)
        client.current.on("tokenAboutToExpire", updateTokenClient) //tokenAboutToExpire triggers 3 minutes before
    }

    function cleanUpListeners() {
        client.current.off("conversationJoined", addConversation)
        client.current.off("conversationLeft", removeConversation)
        client.current.off("conversationUpdated", updateConversations)
        client.current.off("tokenAboutToExpire", updateTokenClient)
    }

    const syncConversations = async () => {
        await initializeClient()
        await updateConversations()
        setUpListeners()
        setLoading(false)
    }

    useEffect(() => {
        syncConversations()
        return cleanUpListeners
    }, [])

    useEffect(() => {
        const syncMessages = async () => {
            const messagesT = await getMessages(selectedConversation)
            selectedConversation.on("messageAdded", addMessage)
            setMessages(messagesT)
        }

        if (selectedConversation !== "not-selected") {
            syncMessages()
        }
    }, [selectedConversation])

    const sendMessage = async (body) => {
        // await selectedConversation.setAllMessagesRead()
        // console.log(selectedConversation._internalState.lastMessage.index )

        // If the skipUpdateConveration is not present the updateConversation listener gets triggered twice.
        // And there would be the green dot showing for a few milliseconds till the setAllMessagesRead is triggered
        skipUpdateConversation.current = true
        await selectedConversation.sendMessage(body)
        skipUpdateConversation.current = false
        await selectedConversation.setAllMessagesRead()
    }

    const deleteConversation = async () => {
        const content = {
            conversation_sid: selectedConversation.sid,
        }

        await sendData(urls.deleteConversation, content)
        setMessages([])
        safeSetSelectedConversation("not-selected")
    }

    const deleteConversationBySID = async (sid) => {
        const content = {
            conversation_sid: sid,
        }

        await sendData(urls.deleteConversation, content)
        setMessages([])
        safeSetSelectedConversation("not-selected")
    }

    function hasNewMessageIncomingConversations(){

        return conversations.some(conversation => conversation._internalState.lastMessage?.index !== conversation._internalState.lastReadMessageIndex)
    }

    const value = {
        conversations: conversations,
        setConversations: setConversations,
        messages: messages,
        selectedConversation: selectedConversation,
        safeSetSelectedConversation: safeSetSelectedConversation,
        sendMessage: sendMessage,
        deleteConversation: deleteConversation,
        deleteConversationBySID: deleteConversationBySID,
        forceRefreshClient: forceRefreshClient,
        lastUpdate: lastUpdate,
        hasNewMessageIncomingConversations: hasNewMessageIncomingConversations
    }

    return (
        <MessagesContext.Provider value={value}>
            <LoadingScreen text="Recupero messaggi" loading={loading}>
                {children}
            </LoadingScreen>
        </MessagesContext.Provider>
    )
}

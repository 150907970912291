import { ButtonBase } from "@mui/material"
import React, { useEffect, useRef, useState } from "react"
import { FormControl, InputGroup } from "react-bootstrap"
import { BsArrowDownShort, BsArrowUpShort, BsInfoCircleFill, BsSearch } from "react-icons/bs"
import { useDashboard } from "../../../../contexts/DashboardProvider"

const Tooltip = ({ text, children }) => {
    const [showTooltip, setShowTooltip] = useState(false)

    const toggleTooltip = () => {
        setShowTooltip(!showTooltip)
    }

    return (
        <div className="relative inline-block">
            <span onMouseEnter={toggleTooltip} onMouseLeave={toggleTooltip} className="relative">
                {children}
            </span>
            {showTooltip && <div className="absolute z-10 bg-gray-800 text-white text-xs p-2 ml-2 rounded-md shadow-md left-full top-1/2 transform -translate-y-1/2 min-w-max">{text}</div>}
        </div>
    )
}

const BonusTooltip = ({ bonuses }) => {
    return (
        <div className="flex flex-col tracking-wide">
            {bonuses.map((x, idx) => (
                <div key={idx}>
                    <span className="font-bold">€{x.amount.toFixed(2)}</span> {x.name}
                </div>
            ))}
        </div>
    )
}

export default function TeamStats({ statsList, setSelectedOperator, selectedOperator }) {
    const { colorTheme } = useDashboard()
    const [filterState, setFilterState] = useState(1)
    const [filterMode, setFilterMode] = useState("totalIncome")
    const [sortedStats, setSortedStats] = useState([...statsList.operators])
    const [searchOperator, setSearchedOperator] = useState("")
    const prevFilterMode = useRef(filterMode)

    useEffect(() => {
        setSelectedOperator(...statsList.operators.filter((statOperatorT) => statOperatorT.id === selectedOperator.id))
    }, [statsList])

    const sortByIncome = (a, b) => {
        return a - b
    }

    useEffect(() => {
        const statsListT = [...statsList.operators]

        switch (filterState) {
            case 0:
                setSortedStats(statsListT.sort((a, b) => sortByIncome(a.name, b.name)))
                break
            case 1:
                setSortedStats(statsListT.sort((a, b) => sortByIncome(a[filterMode], b[filterMode])).reverse())
                break
            case 2:
                setSortedStats(statsListT.sort((a, b) => sortByIncome(a[filterMode], b[filterMode])))
                break
            default:
        }
    }, [filterState, statsList, filterMode])

    const filterStats = (filterModeT) => {
        setFilterMode(filterModeT)

        if (prevFilterMode.current !== filterModeT) {
            // if mode is changed the filter starts at 1
            setFilterState(1)
        } else if (filterState === 2) {
            setFilterState(0)
        } else {
            setFilterState(filterState + 1)
        }
        prevFilterMode.current = filterModeT
    }

    const arrowRender = (filterModeT) => {
        let output = <div></div>
        if (filterModeT === filterMode) {
            if (filterState === 0) {
                output = <div></div>
            } else if (filterState === 1) {
                output = <BsArrowUpShort size={18} />
            } else if (filterState === 2) {
                output = <BsArrowDownShort size={18} />
            }
        }
        return output
    }

    const width = "22%"
    return (
        <>
            <div className="d-flex flex-row align-items-center mt-3 mb-3" style={{ backgroundColor: "whitesmoke" }}>
                <InputGroup>
                    <InputGroup.Text className="bg-white ps-3 pe-0 p-2" style={{ borderBottomLeftRadius: "20px", borderTopLeftRadius: "20px", border: "0px" }}>
                        <BsSearch color="lightslategray" />
                    </InputGroup.Text>

                    <FormControl
                        value={searchOperator}
                        onChange={(e) => setSearchedOperator(e.target.value)}
                        variant="secondary"
                        style={{ borderTopRightRadius: "20px", borderBottomRightRadius: "20px", border: "0px" }}
                        placeholder="Nome operatore"
                    />
                </InputGroup>
            </div>

            <div className="d-flex flex-row justify-content-between text-secondary ps-3 p-2 pe-3 ">
                <div className="ms-2" style={{ width: width }}>
                    Nome
                </div>
                <div
                    style={{ width: width }}
                    onClick={() => {
                        filterStats("servicesIncome")
                    }}
                >
                    <ButtonBase disableTouchRipple={true}>
                        Servizi
                        {arrowRender("servicesIncome")}
                    </ButtonBase>
                </div>
                <div
                    style={{ width: width }}
                    onClick={() => {
                        filterStats("productsIncome")
                    }}
                >
                    <ButtonBase disableTouchRipple={true}>
                        Prodotti
                        {arrowRender("productsIncome")}
                    </ButtonBase>
                </div>
                <div
                    style={{ width: width }}
                    onClick={() => {
                        filterStats("giftCardsIncome")
                    }}
                >
                    <ButtonBase disableTouchRipple={true}>
                        C. Regalo
                        {arrowRender("giftCardsIncome")}
                    </ButtonBase>
                </div>

                <div
                    style={{ width: width }}
                    onClick={() => {
                        filterStats("totalIncome")
                    }}
                >
                    <ButtonBase disableTouchRipple={true}>
                        Totale
                        {arrowRender("totalIncome")}
                    </ButtonBase>
                </div>

                <div
                    style={{ width: width }}
                    onClick={() => {
                        filterStats("revenueRate")
                    }}
                >
                    <ButtonBase disableTouchRipple={true}>
                        Rate
                        {arrowRender("revenueRate")}
                    </ButtonBase>
                </div>

                <div
                    style={{ width: width }}
                    onClick={() => {
                        filterStats("bonusOperator")
                    }}
                >
                    <ButtonBase disableTouchRipple={true}>
                        Bonus
                        {arrowRender("bonusOperator")}
                    </ButtonBase>
                </div>
            </div>

            {sortedStats.map((statOperator) => {
                if (statOperator.name.includes(searchOperator)) {
                    return (
                        <button
                            key={statOperator.id}
                            className="flex flex-row card text-start bg-white justify-content-between mb-2 p-3 ps-4"
                            style={{ borderRadius: "15px", width: "100%", border: "5px", borderColor: "black", outline: "5px" }}
                            onClick={() => {
                                setSelectedOperator(...statsList.operators.filter((statOperatorT) => statOperatorT.id === statOperator.id))
                            }}
                        >
                            <div style={{ fontWeight: "600", width: width }}>{statOperator.name}</div>
                            <div style={{ width: width }}>€{statOperator.servicesIncome.toFixed(2)}</div>
                            <div style={{ width: width }}>€{statOperator.productsIncome.toFixed(2)}</div>
                            <div style={{ width: width }}>€{statOperator.giftCardsIncome.toFixed(2)}</div>

                            <div style={{ width: width, color: colorTheme.primaryColor, fontWeight: "600" }}>€{statOperator.totalIncome.toFixed(2)}</div>
                            <div style={{ width: width }}>€{statOperator.revenueRate.toFixed(2)}</div>

                            <div className="flex items-center gap-1" style={{ width: width }}>
                                {statOperator.monthlyBonus.length > 0 ? (
                                    <Tooltip text={<BonusTooltip bonuses={statOperator.monthlyBonus} />}>€{statOperator.monthlyBonus.reduce((tot, x) => tot + x.amount, 0).toFixed(2)}</Tooltip>
                                ) : (
                                    <div>€0.00</div>
                                )}
                                
                            </div>
                        </button>
                    )
                }
            })}
        </>
    )
}

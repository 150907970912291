import { faArrowLeft, faXmark } from "@fortawesome/free-solid-svg-icons"
import { DateTime } from "luxon"
import React, { useEffect, useState } from "react"
import InputBox from "../elements/InputBox"
import SearchBox from "../elements/SearchBox"
import {
    capitalizeString,
    colors,
    defaultAlertInfo,
    defaultSelectedCustomer,
    defaultSelectedDayTimeInfo,
    getIndexofSelectedTimeslot,
    handleDictionaryChanges,
    handleListofDictionariesChangeNoState,
    isAvailableSelectionNumber,
    isSelectedSelectionNumber,
    onClickSelectionNumber,
    stringToHslColor,
    validCustomerInfo,
} from "../utils/helpers"

import { parsePhoneNumber } from "libphonenumber-js"
import { Image, Spinner } from "react-bootstrap"
import { useDatabase as useGeneralDatabase } from "../../../../../contexts/DatabaseProvider"
import { useSalon } from "../../../../../contexts/SalonProvider"
import { adjustLuminosity, customerFormatter, formatTimeToMinHourString, getData, getDataBooking, urls, urlsBooking } from "../../../../../helper"
import { AppointmentsPanel } from "../../easy-customers/EasyCustomers"
import { useDatabase } from "../contexts/DatabaseProvider"
import AlertBox from "../elements/AlertBox"
import PhoneInputBox from "../elements/PhoneInputBox"
import TimeSelector from "../elements/TimeSelector"
import DateTimePicker from "./DateTimePicker"
import ShowEventModal from "./ShowEventModal"
import TopBarModal from "./TopBarModal"

export function OperatorBadge({ operator, selectedOperator, onClick = undefined, selectable = false, size = 40, extraDetails = true }) {
    let isSelected = false
    if (selectable) isSelected = operator.id === selectedOperator
    if (operator === undefined) {
        return
    }

    function ButtonDivHandler({ children, onClick }) {
        return <>{onClick ? <button onClick={onClick}>{children}</button> : <div>{children}</div>}</>
    }

    return (
        <ButtonDivHandler onClick={onClick}>
            <div className={`flex flex-row items-center rounded-xl ${extraDetails && "p-1"}`} style={{ backgroundColor: isSelected && colors.primaryLight }}>
                {operator?.image ? (
                    <Image className="flex rounded-full !flex-none" style={{ width: size, height: size }} src={operator.image}></Image>
                ) : (
                    <div
                        className={`flex items-center justify-center rounded-full  ${size >= 40 ? "text-[26px]" : "text-[16px]"}`}
                        style={{ height: size, width: size, backgroundColor: stringToHslColor(operator.first_name, 30, 80), color: "white" }}
                    >
                        {operator.first_name[0]}
                    </div>
                )}
                {extraDetails && (
                    <div className="flex flex-col !ms-2">
                        <div className="flex text-sm text-black/90" style={{ fontWeight: "400", color: isSelected && colors.primary }}>
                            {operator.first_name}
                        </div>
                        <div className="text-xs text-black/80" style={{ fontWeight: "200", color: isSelected && colors.primaryBackground }}>
                            {operator.job_title}
                        </div>
                    </div>
                )}
            </div>
        </ButtonDivHandler>
    )
}

function OperatorSection({ operators, className, infoAppointment, setInfoAppointment }) {
    return (
        <div className={className}>
            <div className="!ms-2 text-lg">Operatori</div>
            <div className="relative h-full overflow-auto">
                <div className="absolute flex w-full flex-col gap-4">
                    {operators.map((operator) => {
                        return (
                            <OperatorBadge
                                selectable={true}
                                operator={operator}
                                size={45}
                                key={operator.id}
                                selectedOperator={infoAppointment.selectedOperator}
                                onClick={() => {
                                    setInfoAppointment({ ...infoAppointment, selectedOperator: operator.id })
                                }}
                            />
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

function ListFilterServices({ services, infoAppointment, setInfoAppointment }) {
    const [serchServiceName, setSearchServiceName] = useState("")

    function addToSelectedServices(service) {
        setInfoAppointment({ ...infoAppointment, selectedServices: [...infoAppointment.selectedServices, service] })
    }

    return (
        <>
            <SearchBox
                type="text"
                placeholder="Cerca Servizio"
                value={serchServiceName}
                onChange={(e) => {
                    setSearchServiceName(e.target.value)
                }}
            />
            {/* The relative absoulte scheme is done to !make work the overflow otherwise it does not work */}
            <div className="hide-scroll relative !mt-3 h-full overflow-auto">
                <div className="absolute flex w-full flex-col gap-4">
                    {services.map((service) => {
                        if (infoAppointment.selectedServices.some((selectedService) => selectedService.id === service.id)) {
                            return
                        }
                        if (service.name.toLowerCase().includes(serchServiceName.toLowerCase())) {
                            return (
                                <button key={service.id} onClick={() => addToSelectedServices(service)} className="flex h-10 flex-row items-center rounded-md bg-slate-50/50 px-2">
                                    <div className="rounded-full w-[12px] h-[12px] me-2" style={{ backgroundColor: adjustLuminosity(service.color ? service.color : "#eaeaea", -15) }} />
                                    <div className="leading-none"> {service.name} </div>
                                    {/* <div className="text-xs"> {service.duration} min </div> */}
                                </button>
                            )
                        }
                    })}
                </div>
            </div>
        </>
    )
}

function ListSelectedServices({ infoAppointment, setInfoAppointment }) {
    const { bookingParameters } = useDatabase()
    function removeFromSelectedServices(service) {
        setInfoAppointment({
            ...infoAppointment,
            selectedServices: infoAppointment.selectedServices.filter((selectedService) => service.id !== selectedService.id),
        })
    }

    if (infoAppointment.selectedServices.length === 0) {
        return <div className="!ps-1 text-black/40">Nessun servizio selezionato</div>
    }

    return (
        <div className="hide-scroll  relative h-full overflow-auto ">
            {/* The relative absoulte scheme is done to make work the overflow otherwise it does not work */}
            <div className="absolute flex w-full flex-col gap-4 ">
                {infoAppointment.selectedServices.map((service) => {
                    return (
                        <button
                            key={service.id}
                            onClick={() => {
                                removeFromSelectedServices(service)
                            }}
                            className="flex h-10 flex-row items-center justify-between rounded-md !px-2 gap-2"
                            style={{ backgroundColor: colors.primaryLight }}
                        >
                            <span className="overflow-hidden text-ellipsis whitespace-nowrap tracking-tight w-full text-start" style={{ color: colors.primary }}>
                                {" "}
                                {service.name}{" "}
                            </span>

                            <TimeSelector
                                value={service.duration}
                                setValue={(duration) =>
                                    setInfoAppointment({
                                        ...infoAppointment,
                                        selectedServices: handleListofDictionariesChangeNoState("duration", duration, service, infoAppointment.selectedServices),
                                    })
                                }
                                slotSize={bookingParameters.SLOT_SIZE}
                                classNameExpandingSection="left-[-14px]"
                            >
                                <div className="flex flex-row rounded-md bg-white !p-1 !px-2 text-xs w-[80px] text-primary justify-center tracking-tighter">
                                    {formatTimeToMinHourString(service.duration)}
                                </div>
                            </TimeSelector>
                        </button>
                    )
                })}
            </div>
        </div>
    )
}

function ServicesSection({ services, className, infoAppointment, setInfoAppointment }) {
    return (
        <div className={className}>
            <div className="flex w-[48%] flex-col">
                <div className="!mb-3 !ms-2 text-lg">Servizi</div>
                <ListFilterServices services={services} infoAppointment={infoAppointment} setInfoAppointment={setInfoAppointment} />
            </div>

            <div className="flex w-[52%] flex-col">
                <div className="!mb-3 !ms-2 text-lg">Servizi Selezionati</div>
                <ListSelectedServices infoAppointment={infoAppointment} setInfoAppointment={setInfoAppointment} />
            </div>
        </div>
    )
}

function DateTimeSection({ className, infoAppointment, setInfoAppointment, selectedAppointment }) {
    function initialCalendarDate(timeslot) {
        // console.log("initialDate", timeslot)
        return timeslot === "not-selected" ? DateTime.now().startOf("month") : DateTime.fromMillis(timeslot).startOf("month")
    }

    const { retrieveMonthlyFreeTimeslots, monthlyFreeTimeslots } = useDatabase()
    const [calendarDate, setCalendarDate] = useState(initialCalendarDate(infoAppointment.selectedDayTimeInfo.timeSlot))

    useEffect(() => {
        // console.log("getting here")
        if (infoAppointment.selectedServices.length > 0) {
            const durationServices = infoAppointment.selectedServices.reduce((tot, service) => (tot = tot + Number(service.duration)), 0)
            retrieveMonthlyFreeTimeslots(calendarDate, durationServices, infoAppointment.selectedOperator, selectedAppointment.id, undefined)
        }
    }, [infoAppointment.selectedServices, infoAppointment.selectedOperator, calendarDate])

    if (infoAppointment.selectedServices.length === 0) {
        return <div className="flex grow items-center justify-center text-2xl tracking-normal text-black/20"> Seleziona servizi</div>
    }
    // console.log(monthlyFreeTimeslots)
    // console.log(DateTime.fromMillis(infoAppointment.selectedDayTimeInfo.dayTimestamp).toString())

    return (
        <DateTimePicker
            className={className}
            calendarDate={calendarDate}
            setCalendarDate={setCalendarDate}
            infoData={infoAppointment}
            setInfoData={setInfoAppointment}
            availableTimeslots={monthlyFreeTimeslots}
            isAvailableSelectionNumber={isAvailableSelectionNumber}
            isSelectedSelectionNumber={isSelectedSelectionNumber}
            onClickSelectionNumber={onClickSelectionNumber}
        />
    )
}

function CreateEditAppointment({ props }) {
    const { operators, services, selectedAppointment, infoAppointment, setInfoAppointment, setModalPage, deleteAppointmentDatabase } = props
    const { monthlyFreeTimeslots } = useDatabase()

    function checkIfTimeslotExists() {
        if (infoAppointment.selectedServices.length < 1) return false
        const { availableTimeslot } = getIndexofSelectedTimeslot(monthlyFreeTimeslots, infoAppointment)
        return availableTimeslot
    }

    return (
        <>
            <TopBarModal title={selectedAppointment === "not-selected" ? "Nuovo Appuntamento" : "Modifica appuntamento"} />
            <div className="!mt-4 flex h-full w-full flex-row divide-x divide-black/5">
                <OperatorSection className="flex w-[20%] flex-col gap-2 !pe-2 " operators={operators} infoAppointment={infoAppointment} setInfoAppointment={setInfoAppointment} />
                <ServicesSection className="flex w-[50%]  flex-row gap-4 !px-2" services={services} infoAppointment={infoAppointment} setInfoAppointment={setInfoAppointment} />
                <DateTimeSection className="flex w-[30%] flex-col !pl-2" infoAppointment={infoAppointment} setInfoAppointment={setInfoAppointment} selectedAppointment={selectedAppointment} />
            </div>

            <div className="!mt-5 flex w-full  items-center justify-center space-x-4">
                <DeleteAppointmentButton deleteAppointmentDatabase={deleteAppointmentDatabase} selectedAppointment={selectedAppointment} />
                <button
                    className="flex h-10 w-64 items-center justify-center rounded-xl text-white"
                    style={{ backgroundColor: checkIfTimeslotExists() ? colors.primary : "lightgray" }}
                    disabled={!checkIfTimeslotExists()}
                    onClick={() => setModalPage(2)}
                >
                    Avanti
                </button>
            </div>
        </>
    )
}

function CustomerSection({ className, infoAppointment, setInfoAppointment, alertInfo, handleSelectedCustomerChange }) {
    const [searchCustomer, setSearchCustomer] = useState("")
    const { customers } = useGeneralDatabase()

    function searchCustomerFormatter(entry) {
        return entry.first_name + " " + entry.last_name
    }

    function searchCustomerFilter(entry, value) {
        return entry.phone.includes(value)
    }

    return (
        <div className={className}>
            <div className="flex flex-col space-y-5">
                <div className="!ms-2 text-lg -mb-2">Informazioni Cliente</div>
                <SearchBox
                    type="text"
                    placeholder="Cerca Cliente"
                    value={searchCustomer}
                    onChange={(e) => {
                        setSearchCustomer(e.target.value)
                    }}
                    data={customers}
                    searchData={searchCustomerFormatter}
                    customFilter={searchCustomerFilter}
                    onClick={(customer) => {
                        const customerFormatted = customerFormatter(customer)

                        handleDictionaryChanges("selectedCustomer", customerFormatted, setInfoAppointment)
                        setSearchCustomer(searchCustomerFormatter(customerFormatted))
                    }}
                />
                <div className="flex flex-row space-x-5 ">
                    <InputBox label={"Nome"} name="first_name" placeholder={"Mario"} value={infoAppointment.selectedCustomer.first_name} onChange={handleSelectedCustomerChange} />
                    <InputBox label={"Cognome"} name="last_name" placeholder={"Rossi"} value={infoAppointment.selectedCustomer.last_name} onChange={handleSelectedCustomerChange} />
                </div>

                <InputBox label={"Email"} name="email" placeholder={"mario.rossi@gmail.com"} value={infoAppointment.selectedCustomer.email} onChange={handleSelectedCustomerChange} />

                {/* {console.log(infoAppointment.selectedCustomer)} */}
                <PhoneInputBox
                    label="Phone"
                    name="phone"
                    placeholder="XXX XXX XX XX"
                    onChange={handleSelectedCustomerChange}
                    value={infoAppointment.selectedCustomer.phone}
                    defaultCountryCode={infoAppointment.selectedCustomer.countryCode}
                />

                <AlertBox className="!mt-2" msg={alertInfo.msg} showStatus={alertInfo.status} />
            </div>
        </div>
    )
}

function ServiceTimeRecap({ infoAppointment }) {
    const durationServices = infoAppointment.selectedServices.reduce((tot, service) => (tot = tot + Number(service.duration)), 0)

    function formatDateAvailabilityString(timeslot) {
        const date = DateTime.fromMillis(timeslot)
        const dateString = capitalizeString(date.toLocaleString({ weekday: "long", month: "long", day: "numeric" })) + " dalle "
        const timeslotString = date.toLocaleString(DateTime.TIME_24_SIMPLE) + " - " + date.plus({ minutes: durationServices }).toLocaleString(DateTime.TIME_24_SIMPLE)
        const dateTimeString = (dateString + timeslotString).split(" ")

        return (
            <>
                <span>{dateTimeString[0]} </span>
                <span className="font-medium">{dateTimeString[1]} </span>
                <span>{dateTimeString.slice(2, 3)} </span>
                <span className="font-medium">{dateTimeString.slice(4, 7)}</span>
            </>
        )
    }

    return (
        <div className="!mt-3 flex flex-col">
            <div className="!ps-2 font-light" style={{ color: colors.primary, fontSize: "16px" }}>
                {/* Lunedì 25 
                Marzo dalle 12:25 - 13:15 */}

                {formatDateAvailabilityString(infoAppointment.selectedDayTimeInfo.timeSlot)}
            </div>
            <div className="!mt-1 flex flex-row rounded-md bg-slate-50  !p-3">
                <div className="flex flex-col gap-2">
                    {infoAppointment.selectedServices.map((service) => {
                        return (
                            <div key={service.id} style={{ color: colors.primary }}>
                                {service.name}
                            </div>
                        )
                    })}
                </div>
                <div className="flex grow flex-col" style={{ color: colors.primary }}>
                    {/* <div className="flex items-center justify-end">
                        <FontAwesomeIcon icon={faMoneyBill} size="lg" color={colors.primaryBackground} />
                        <div className="!me-3 !ms-1">{priceServices}</div>
                        <FontAwesomeIcon icon={faClock} size="lg" color={colors.primaryBackground} />
                        <div className="!ms-1">{durationServices}</div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

function NoteHolder({ infoAppointment, handleSelectedCustomerChange }) {
    return (
        <InputBox
            label="Nota"
            name="note"
            className="!mt-3 h-full"
            placeholder="Aggiungi nota"
            value={infoAppointment.selectedCustomer.note}
            onChange={handleSelectedCustomerChange}
            isTextArea={true}
        />
    )
}

function ReviewSection({ className, infoAppointment, handleSelectedCustomerChange }) {
    const { operators } = useDatabase()
    const operator = operators.filter((operator) => operator.id === infoAppointment.selectedOperator)[0]

    return (
        <div className={className}>
            <div className="flex grow flex-col">
                <div className="!mb-3 !ms-2 text-lg">Riepilogo appuntamento</div>
                <OperatorBadge operator={operator} />
                <ServiceTimeRecap infoAppointment={infoAppointment} />
                <NoteHolder infoAppointment={infoAppointment} handleSelectedCustomerChange={handleSelectedCustomerChange} />
            </div>
        </div>
    )
}

function ReviewAppointment({ props }) {
    const { closeModal, setModalPage, infoAppointment, setInfoAppointment, selectedAppointment, refreshDataCalendar, deleteAppointmentDatabase } = props
    const { postAppointment } = useDatabase()
    const { selectedSalon } = useSalon()
    const { updateCustomers } = useGeneralDatabase()
    const [alertInfo, setAlertInfo] = useState(defaultAlertInfo())
    const [isProcessing, setIsProcessing] = useState(false)

    // function delay(ms) {
    //     return new Promise((resolve) => setTimeout(resolve, ms))
    // }

    // async function executeAfterDelay() {
    //     await delay(10000) // Wait for 10 seconds
    //     closeModal()
    // }

    async function saveAppointmentDatabase(infoAppointment) {
        if (!validCustomerInfo(infoAppointment.selectedCustomer, setAlertInfo)) return

        const appointment_details = infoAppointment.selectedServices.map((selectedService) => ({
            duration: Number(selectedService.duration),
            service: selectedService.id,
            operator: infoAppointment.selectedOperator,
            booking_source: "internal",
        }))

        const appointment = {
            start_time: DateTime.fromMillis(infoAppointment.selectedDayTimeInfo.timeSlot).toISO(),
            appointment_details: appointment_details,
            customer: { ...infoAppointment.selectedCustomer, phone: `+${infoAppointment.selectedCustomer.countryCode.dialCode + infoAppointment.selectedCustomer.phone}` },
            salon: selectedSalon.id,
            appointment_waiting_list: selectedAppointment.appointment_waiting_list_id,
        }

        let response
        try {
            setIsProcessing(true)
            if (selectedAppointment === "not-selected" || selectedAppointment.type === "waiting_list") {
                response = await postAppointment(appointment)
            } else {
                response = await postAppointment({ ...appointment, appointment_details_id: selectedAppointment.id })
            }
            await refreshDataCalendar()
            await updateCustomers(appointment.customer.id)
            setIsProcessing(false)
            closeModal()
        } catch (err) {
            setAlertInfo({ status: true, msg: JSON.stringify(err.message) })
            setIsProcessing(false)
        }
    }

    function isInfoComplete(infoAppointment) {
        const checkKeys = ["first_name", "last_name", "phone"]

        for (const key of checkKeys) {
            if (infoAppointment.selectedCustomer[key] === "") return false
        }
        return true
    }

    function handleSelectedCustomerChange(e) {
        const { name, value } = e.target
        handleDictionaryChanges("selectedCustomer", { ...infoAppointment.selectedCustomer, [name]: value }, setInfoAppointment)
    }

    return (
        <>
            <TopBarModal
                activateBack={true}
                backIcon={faArrowLeft}
                setModalPage={setModalPage}
                onClickBack={() => {
                    setModalPage(1)
                }}
                title={selectedAppointment === "not-selected" ? "Nuovo Appuntamento" : "Modifica appuntamento"}
            />
            <div className="!mt-4 flex h-full w-full flex-row divide-x divide-black/5 ">
                <CustomerSection
                    infoAppointment={infoAppointment}
                    setInfoAppointment={setInfoAppointment}
                    alertInfo={alertInfo}
                    handleSelectedCustomerChange={handleSelectedCustomerChange}
                    className="flex w-[30%] gap-2 !pe-4"
                />
                <ReviewSection
                    infoAppointment={infoAppointment}
                    setInfoAppointment={setInfoAppointment}
                    handleSelectedCustomerChange={handleSelectedCustomerChange}
                    className="flex w-[35%] gap-2 !px-4"
                />
                <HistoryAppointmentsCustomer selectedCustomer={infoAppointment.selectedCustomer} className="flex w-[35%] gap-2 !px-4" />
            </div>

            <div className="!mt-5 flex w-full  items-center justify-center space-x-4">
                <DeleteAppointmentButton deleteAppointmentDatabase={deleteAppointmentDatabase} selectedAppointment={selectedAppointment} />

                <button
                    className="flex h-10 w-64 items-center justify-center rounded-xl text-white"
                    style={{ backgroundColor: isInfoComplete(infoAppointment) ? colors.primary : "lightgray" }}
                    disabled={!isInfoComplete(infoAppointment) || isProcessing}
                    onClick={() => saveAppointmentDatabase(infoAppointment)}
                >
                    {isProcessing && <Spinner className="me-2" as="span" animation="border" size="sm" role="status" />}
                    Salva
                </button>
            </div>
        </>
    )
}

function HistoryAppointmentsCustomer({ className, selectedCustomer }) {
    const { selectedSalon } = useSalon()
    const [futureAppointments, setFutureAppointments] = useState([])
    const [pastAppointments, setPastAppointments] = useState([])

    useEffect(() => {
        // console.log(selectedCustomer)
        const getStatsCustomer = async () => {
            if (selectedCustomer.id !== undefined) {
                const pastAppointments = await getData(urls.statsCustomers + selectedCustomer.id + "/" + selectedSalon.id + "/")
                const futureAppointments = await getDataBooking(urlsBooking.getFutureAppointments + selectedCustomer.id + "/" + selectedSalon.id + "/")
                setFutureAppointments(futureAppointments)
                setPastAppointments(pastAppointments)
            }
        }
        getStatsCustomer()
    }, [selectedCustomer])

    return (
        <div className={className}>
            <div className="flex grow flex-col overflow-auto">
                <div className="!mb-3 !ms-2 text-lg">Storico appuntamenti</div>
                <div className="hide-scroll  relative h-full overflow-auto ">
                    <div className="absolute flex w-full flex-col  ">
                        <AppointmentsPanel futureAppointments={futureAppointments} pastAppointments={pastAppointments} classNameCard="bg-gray-50" />
                    </div>
                </div>
            </div>
        </div>
    )
}

function DeleteAppointmentButton({ selectedAppointment, deleteAppointmentDatabase }) {
    const [isProcessing, setIsProcessing] = useState(false)
    const [showModal, setShowModal] = useState(false)

    return (
        <>
            {selectedAppointment !== "not-selected" && (
                <button onClick={() => setShowModal(true)} className="flex h-10 w-64 items-center justify-center rounded-xl bg-black/10 ">
                    Elimina
                </button>
            )}
            <ShowEventModal showModal={showModal}>
                <TopBarModal closingIcon={faXmark} closeModal={() => setShowModal(false)} title={"Cancella appuntamento"} classNameTitle="text-lg tracking-tight" />
                <div className="mt-2 leading-5 w-64">Sei sicuro di voler cancellare questo appuntamento?</div>
                <div className="!mt-5 flex w-full  items-center justify-center space-x-4">
                    <button
                        disabled={isProcessing}
                        className="flex h-10 w-full items-center justify-center rounded-xl bg-red-600 text-white"
                        onClick={() => deleteAppointmentDatabase(setIsProcessing)}
                    >
                        {isProcessing && <Spinner className="me-2" as="span" animation="border" size="sm" role="status" />}
                        Elimina
                    </button>
                </div>
            </ShowEventModal>
        </>
    )
}

export default function AppointmentModal({ refreshDataCalendar, selectedAppointment, calendarOperator, calendarAccessedMode, modalPage, setModalPage, closeModal }) {
    const { operators, services, deleteAppointment } = useDatabase()
    const { selectedSalon } = useSalon()

    let defaultInfoAppointment = {
        selectedOperator: calendarOperator.id,
        selectedServices: [],
        selectedDayTimeInfo: defaultSelectedDayTimeInfo,
        selectedCustomer: { ...defaultSelectedCustomer, salon: [selectedSalon.id] },
    }

    const [infoAppointment, setInfoAppointment] = useState()

    useEffect(() => {
        //This weird scheme is created because the modals must load state on the higher up level possible,
        //The current architecture makes the modal load and follow states even if not showing. Put the switch of the modal in calendar and all these weird states would disappear.
        //Set appointment if it has been clicked
        if (selectedAppointment !== "not-selected") {
            const selectedOperator = selectedAppointment.operator
            // console.log(selectedAppointment)
            const selectedServices = [{ ...selectedAppointment.service, duration: selectedAppointment.duration }]
            const appointmentDate = DateTime.fromISO(selectedAppointment.start_time)
            const selectedDayTimeInfo = { dayTimestamp: appointmentDate.startOf("day").toMillis(), hour: appointmentDate.hour, timeSlot: appointmentDate.toMillis() }
            const selectedCustomerPhone = parsePhoneNumber(selectedAppointment.customer.phone)
            const selectedCustomer = {
                ...selectedAppointment.customer,
                salon: [selectedSalon.id],
                phone: selectedCustomerPhone.nationalNumber,
                countryCode: { iso2: selectedCustomerPhone.country.toLowerCase(), dialCode: selectedCustomerPhone.countryCallingCode },
            }

            setInfoAppointment({
                selectedOperator: selectedOperator,
                selectedServices: selectedServices,
                selectedDayTimeInfo: selectedDayTimeInfo,
                selectedCustomer: selectedCustomer,
            })
        } else if (calendarAccessedMode.mode === "click") {
            setInfoAppointment({ ...defaultInfoAppointment, selectedDayTimeInfo: calendarAccessedMode.payload })
        }
    }, [selectedAppointment, calendarAccessedMode, selectedSalon])

    async function deleteAppointmentDatabase(setIsProcessing) {
        setIsProcessing(true)
        await deleteAppointment(selectedAppointment.id)
        await refreshDataCalendar()
        setIsProcessing(false)

        closeModal()
    }

    const props = {
        operators: operators,
        services: services,
        infoAppointment: infoAppointment,
        setInfoAppointment: setInfoAppointment,
        setModalPage: setModalPage,
        closeModal: closeModal,
        refreshDataCalendar: refreshDataCalendar,
        selectedAppointment: selectedAppointment,
        deleteAppointmentDatabase: deleteAppointmentDatabase,
        calendarOperator: calendarOperator,
    }

    // console.log(infoAppointment)
    return (
        <>
            {infoAppointment && (
                <>
                    {modalPage === 1 && <CreateEditAppointment props={props} />}
                    {modalPage === 2 && <ReviewAppointment props={props} />}
                </>
            )}
        </>
    )
}

import { parsePhoneNumber } from "libphonenumber-js"
import React, { useEffect, useState } from "react"
import { Alert, Button, FormControl, InputGroup } from "react-bootstrap"
import { useDashboard } from "../../../../contexts/DashboardProvider"
import { useDatabase } from "../../../../contexts/DatabaseProvider"
import { customerFormatter, putDataBooking, stringToHslColor, urlsBooking } from "../../../../helper"
import InputBox from "../easy-calendar/elements/InputBox"
import { handleDictionaryChanges, validCustomerInfo } from "../easy-calendar/utils/helpers"
import PhoneInputBox from "../easy-calendar/elements/PhoneInputBox"

export default function BioPanel({ advancedStatsCustomer, selectedCustomer }) {
    const defaultAlertBox = { mode: "", msg: "", status: false }
    const { colorTheme } = useDashboard()
    const { updateCustomers } = useDatabase()
    const [editingCustomer, setEditingCustomer] = useState(customerFormatter(selectedCustomer))
    const [alertBox, setAlertBox] = useState(defaultAlertBox)

    useEffect(() => {
        setEditingCustomer(customerFormatter(selectedCustomer))
        setAlertBox(defaultAlertBox)
    }, [selectedCustomer])

    const saveCustomerInfo = async () => {
        if (!validCustomerInfo(editingCustomer, setAlertBox)) return

        try {
            await putDataBooking(urlsBooking.updateCustomer + editingCustomer.id + "/", { ...editingCustomer, phone: `+${editingCustomer.countryCode.dialCode + editingCustomer.phone}` })
        } catch (error) {
            setAlertBox({ mode: "danger", msg: JSON.stringify(error.response.data), status: true })
            return
        }

        setAlertBox({ mode: "success", msg: "Informazioni aggiornate con successo", status: true })
        updateCustomers(editingCustomer.id)
    }

    function handleSelectedCustomerChange(e) {
        const { name, value } = e.target
        handleDictionaryChanges(name, value, setEditingCustomer)
    }

    return (
        <div className="flex flex-col">
            <div className="d-flex card shadow-sm mb-3 p-4" style={{ borderRadius: "15px" }}>
                <div className="d-flex justify-content-center mt-3">
                    <div
                        className="d-flex justify-content-center rounded-circle align-items-center "
                        style={{
                            height: "50px",
                            width: "50px",
                            fontSize: "26px",
                            backgroundColor: stringToHslColor(editingCustomer.full_name, 30, 80),
                            color: "white",
                        }}
                    >
                        {editingCustomer.full_name[0]}
                    </div>
                </div>
                {/* {console.log(editingCustomer)} */}
                <div className="d-flex flex-column  mt-3 mb-3 gap-1">
                    <InputBox
                        value={editingCustomer.first_name}
                        name="first_name"
                        onChange={handleSelectedCustomerChange}
                        classNameBox="!outline-none border-b-[1px] !rounded-none w-48 self-center "
                        classNameInput="h-6  text-center text-[18px] font-semibold"
                    />
                    <InputBox
                        value={editingCustomer.last_name}
                        name="last_name"
                        onChange={handleSelectedCustomerChange}
                        className="mb-2 "
                        classNameBox="!outline-none border-b-[1px] !rounded-none w-48 self-center"
                        classNameInput="h-6 text-center text-[18px] font-semibold"
                    />
                    <InputBox
                        value={editingCustomer.email}
                        name="email"
                        onChange={handleSelectedCustomerChange}
                        className="mb-1"
                        classNameBox="!outline-none border-b-[1px] !rounded-none  "
                        classNameInput="h-4 text-center text-[12px] text-secondary"
                    />
                    <PhoneInputBox id={editingCustomer.id} name="phone" onChange={handleSelectedCustomerChange} value={editingCustomer.phone} defaultCountryCode={editingCustomer.countryCode} />
                </div>
                <div className="d-flex flex-row ps-3 pe-3 justify-content-around">
                    <div className="d-flex flex-column">
                        <div className="text-center" style={{ fontSize: "20px", fontWeight: "600", color: colorTheme.primaryColor }}>
                            {advancedStatsCustomer.numberServicesSold}
                        </div>
                        <div className="text-secondary">N. Servizi</div>
                    </div>
                    <div className="d-flex flex-column">
                        <div className="text-center" style={{ fontSize: "20px", fontWeight: "600", color: colorTheme.primaryColor }}>
                            {advancedStatsCustomer.numberProductsSold}
                        </div>
                        <div className="text-secondary">N. Prodotti</div>
                    </div>
                </div>

                <div>
                    <hr className="mt-4 mb-4 bg-dark border-1 border-top border-secondary"></hr>
                </div>

                <div className="d-flex flex-column ps-3 pe-3">
                    <div className="d-flex mb-2 justify-content-between align-items-center">
                        <div style={{ fontSize: "17px", fontWeight: "600" }}>Note</div>
                    </div>
                    <InputGroup>
                        <FormControl
                            as="textarea"
                            value={editingCustomer.note}
                            name="note"
                            onChange={handleSelectedCustomerChange}
                            placeholder={"Inserisci nota"}
                            style={{ borderRadius: "10px", backgroundColor: "whitesmoke", height: "275px", fontSize: "13px", border: "0", marginLeft: "-15px", marginRight: "-15px" }}
                        ></FormControl>
                    </InputGroup>
                </div>

                <div className="d-flex  mt-3 pe-3 ps-3" style={{ borderRadius: "25px" }}>
                    <Button
                        className="d-flex flex-fill text-center"
                        variant={colorTheme.button}
                        style={{ borderRadius: "15px", justifyContent: "center", marginLeft: "-15px", marginRight: "-15px" }}
                        size="s"
                        onClick={() => {
                            saveCustomerInfo()
                        }}
                    >
                        <span className="ms-2">Salva</span>
                    </Button>
                </div>
            </div>
            {alertBox.status && (
                <Alert className="text-center" variant={alertBox.mode}>
                    {alertBox.msg}
                </Alert>
            )}
        </div>
    )
}

import React, { useEffect, useState } from "react"
import { useDashboard } from "../../../../contexts/DashboardProvider"
import { useMessages } from "../../../../contexts/MessagesProvider"
import { getData, urls } from "../../../../helper"
import MessageInput from "./MessageInput"
import MessagesBubbles from "./MessagesBubbles"
import TemplatePanel from "./TemplatePanel"
import { CircularProgress } from "@mui/material"

const ConversationManager = () => {
    const { conversations, selectedConversation } = useMessages()

    if (selectedConversation === "not-selected") {
        return (
            <div className="d-flex justify-content-center " style={{ fontSize: "48px", paddingTop: "300px", color: "lightgray" }}>
                {conversations.length === 0 ? "Nessun messaggio" : "Seleziona messaggio"}
            </div>
        )
    } else {
        return (
            <div className="flex flex-col h-full ">
                <MessagesBubbles />
                <MessageInput />
            </div>
        )
    }
}

const TemplateManager = () => {
    const [customers, setCustomers] = useState([])
    const [unselectedCustomers, setUnselectedCustomers] = useState([])

    const { selectedTemplate } = useDashboard()

    useEffect(() => {
        const retrieveCustomersMain = async () => {
            const customers = await getData(urls.customers)
            // console.log(customers)
            setUnselectedCustomers(
                customers.reduce((customersAll, customerT) => {
                    return { ...customersAll, [customerT.id]: false }
                }, {})
            )
            setCustomers(customers)
        }
        retrieveCustomersMain()
    }, [])

    if (selectedTemplate === "not-selected") {
        return (
            <div className="d-flex justify-content-center " style={{ fontSize: "48px", paddingTop: "300px", color: "lightgray" }}>
                Seleziona modello
            </div>
        )
    }

    if (customers.length === 0)
        return (
            <div className="d-flex justify-content-center " style={{ fontSize: "48px", paddingTop: "300px", color: "lightgray" }}>
                <CircularProgress />
            </div>
        )
    // console.log(customers)
    return <TemplatePanel customers={customers} unselectedCustomers={unselectedCustomers}/>
}

export default function EasyMessages() {
    const { messagesMode } = useDashboard()

    return (
        <>
            {messagesMode === "conversations" && <ConversationManager />}
            {messagesMode === "templates" && <TemplateManager />}
        </>
    )
}
